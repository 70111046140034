import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, Space, Typography } from '@pitchero/react-ui';
import { Trans } from '../../lib/i18n';
import Graphic from './graphic';

const AppAdvert = ({ clubName, style }) => (
  <Rectangle fill="primaryDark">
    <Cushion all="medium">
      <Typography align="center" component="div" style={style}>
        <Graphic width={320} />
        <Space bottom="medium">
          <Typography
            align="center"
            component="h3"
            preset="subtitle--large"
            color="primaryDarkContrast"
          >
            <Trans i18nKey="common:app_advert_title" values={{ clubName }}>
              {`Find ${clubName} on the Pitchero Club App`}
            </Trans>
          </Typography>
        </Space>
        <Typography align="center" preset="body--small" color="primaryDarkContrast">
          <Trans i18nKey="common:app_advert_tagline">
            The best way to keep up-to-date with your club
          </Trans>
        </Typography>

        <Space vertical="medium">
          <Space horizontal="small">
            <a
              href="https://app.appsflyer.com/id590231780?pid=club_website&c=club_login"
              style={{ display: 'inline-block' }}
            >
              <img
                src="https://images.pitchero.com/up/itunes-button.png"
                alt="Download on the App Store"
                width={160}
                height={48}
              />
            </a>
          </Space>
          <Space horizontal="small">
            <a
              href="https://app.appsflyer.com/com.pitchero.ph?pid=club_website&c=club_login"
              style={{ display: 'inline-block' }}
            >
              <img
                src="https://images.pitchero.com/up/play-store-button.png"
                alt="Get it on Google Play"
                width={160}
                height={48}
              />
            </a>
          </Space>
        </Space>
      </Typography>
    </Cushion>
  </Rectangle>
);

AppAdvert.defaultProps = {
  style: {},
};

AppAdvert.propTypes = {
  clubName: PropTypes.string.isRequired,
  style: PropTypes.shape,
};

export default AppAdvert;
